/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useParams } from "react-router-dom";
import { Column, Row } from "../common/Flex.js";
import { H1, H2 } from "../common/Label";
import Button from "../common/Button.js";
import _ from "lodash";
import { humanize } from "underscore.string";
import ReactDOMServer from "react-dom/server";
import { useEffect, useContext } from "react";
import { AnswerContext } from "../contexts/AnswersContext";
import { useHistory } from "react-router-dom";
const css_container = css`
  width: 100%;
  justify-content: start;
  margin-top: 20px;
  padding: 0 80px 20px;
  color: white;
`;

const css_flyout_container = css`
  width: 100%;
  justify-content: start;
  margin-top: 20px;
  padding-bottom: 20px;
  color: black;
`;

const css_h1 = css`
  text-align: start;
  padding-bottom: 20px;
`;

const css_score_container = css`
  margin-bottom: 20px;
`;

const css_button = css`
  margin: 20px 0px;
`;

let result_report = `Result Audit

`;

let score_report = ``;

export default function Result(props) {
  let isFlyout = props.isFlyout;
  let Params = useParams();
  let Answers = useContext(AnswerContext);
  let assessmentId = Params.assessment_id || Answers.activeAssessment;

  useEffect(() => {
    Answers.loadAnswers(assessmentId);
  }, []);

  let score = calculateScore(Answers.answers);
  let risk_score = (score.total_risk_score || {}).risk;
  let needs = Object.keys(score)
    .filter((n) => n.match(/^needs/))
    .map((n) => score[n]);

  let classification;

  if (Object.keys(score).length && score !== "N/A") {
    classification = calculateClassification(
      score.total_risk_score.risk,
      score.refusals.risk,
      score.unknowns.risk,
      Answers.answers
    );
  }
  let History = useHistory();
  return (
    <Column css={isFlyout ? css_flyout_container : css_container}>
      <H1 css={css_h1}>Assessment {assessmentId} Results</H1>
      <Score score={{ classification, value: risk_score }} />
      <Needs needs={needs} />
      <Row right style={{ gap: "10px", padding: "10px", alignItems: "center" }}>
        <button
          style={{ height: "51px" }}
          onClick={() => {
            saveScoreReport(
              score,
              "Scoring Metadata",
              `${(Answers.answers.A2 || "").qanswer} - ${assessmentId}`
            );
          }}
        >
          Download scoring metadata
        </button>
        {!isFlyout && (
          <Button
            css={css_button}
            onClick={() => History.push("/participants")}
          >
            Back to participants
          </Button>
        )}
      </Row>
    </Column>
  );
}

function Score({ score }) {
  if (score.classification === "refusal") {
    return (
      <Column css={css_score_container} center>
        <H2 css={css_h1}>Score</H2>
        <Row>
          Due to high number of Refusals the risk score could not be calculated
        </Row>
      </Column>
    );
  }
  return (
    <Column css={css_score_container} center>
      {/* <Row left>
        <H2 css={css_h1}>Score</H2>
      </Row> */}
      {/* <Row style={{}}>Risk Score: {score.value}</Row>
      <Row style={{ fontWeight: "bold" }}>
        Risk Classification: {score.classification}
      </Row> */}
    </Column>
  );
}

function Needs({ needs }) {
  return (
    <Column center>
      <Row>
        <H2 css={css_h1}>Needs Flags</H2>
      </Row>

      {needs.map((n, i) => {
        let result;

        if (n.needs_result === true || n.needs_result === false) {
          result = n.needs_result ? "True" : "False";
        } else {
          result = n.needs_result;
        }
        return (
          <Row
            key={i}
            spaceBetween
            style={{ width: "350px", paddingTop: "5px" }}
          >
            <span style={{ textAlign: "left", paddingRight: "10px" }}>
              {n.label}
            </span>
            <span style={{ fontWeight: "bold" }}>{result}</span>
          </Row>
        );
      })}
    </Column>
  );
}

function calculateClassification(risk_score, refusals, unknows, answers) {
  if (!answers || !Object.keys(answers).length) {
    return "N/A";
  }
  let levels;
  let refusal_threshold = 4;
  let unknown_threshold = 3;
  let classification = "";
  if (_.get(answers, "TYPE.qanswer") === "f") {
    levels = {
      low: { label: "Low Risk", min: 0, max: 10 },
      moderate: { label: "Moderate Risk", min: 11, max: 41 },
      high: { label: "High Risk", min: 42, max: 61 },
      very_high: { label: "Very High Risk", min: 62, max: 10000 },
    };
  } else {
    levels = {
      low: { label: "Low Risk", min: 0, max: 10 },
      moderate: { label: "Moderate Risk", min: 11, max: 21 },
      high: { label: "High Risk", min: 22, max: 32 },
      very_high: { label: "Very High Risk", min: 33, max: 10000 },
    };
  }

  for (let [key, { max, min, label }] of Object.entries(levels)) {
    if (risk_score < max && risk_score >= min) {
      classification = label;
    }
  }

  if (refusals >= refusal_threshold || unknows >= unknown_threshold) {
    classification = "refusal";
  }

  return classification;
}

function calculateScore(answers) {
  if (!answers || !Object.keys(answers).length) {
    return "N/A";
  }

  if (_.get(answers, "TYPE.qanswer") === "f") {
    result_report += `Assessment Type: LONG`;
    return calculateLongScore(answers);
  } else {
    result_report += `Assessment Type: Short`;
    return calculateShortScore(answers);
  }
}

function calculateShortScore(answers) {
  let score_categories = {
    total_risk_score: {
      isAnswer: reggy("[MR]\\d+"),
      risk: sumScores,
    },

    /* NEW SCORING */
    needs_disqualified: {
      isAnswer: includes(["M1", "M2"]),
      label: "Does not qualify for VTC",
      score: (answers) => {
        if (answers.M1.qanswer === "No") {
          return true;
        }

        if (Array.isArray(answers.M2.qanswer)) {
          answers.Q11b.qanswer.forEach((answer) => {
            if (
              ["Never in Armed Forces/National Guard/Reserves"].includes(answer)
            ) {
              return true;
            }
          });
        }

        return answers.M2.qanswer.includes(
          "Never in Armed Forces/National Guard/Reserves"
        );
      },
    },
    needs_potentially_eligible: {
      isAnswer: includes(["M1", "M4", "M6"]),
      label: "Potentially Eligible for VHA Benefits",
      score: (answers) => {
        if (answers.M1.qanswer === "Yes") {
          if (
            answers.M4.qanswer.match("Retired") ||
            answers.M4.qanswer.match("General discharge") ||
            answers.M4.qanswer.match("Honorable discharge")
          ) {
            return true;
          }
        } else if (answers.M6.qanswer === "Yes") {
          return true;
        }
        return false;
      },
    },
    needs_combat: {
      isAnswer: includes(["M5"]),
      label: "Combat",
      score: (answers) => {
        if (answers.M5.qweight === "a") {
          return true;
        }
        return false;
      },
    },
    needs_duis: {
      isAnswer: includes(["R3"]),
      label: "Multiple DUI/DWI Convictions",
      score: (answers) => {
        if (Number(answers.R3.qweight) >= 1) {
          return true;
        }

        return false;
      },
    },
    needs_ipv: {
      isAnswer: includes(["R6", "R7"]),
      label: "IPV - Batterer",
      score: (answers) => {
        if (Number(answers.R6.qweight) >= 1) {
          return true;
        }
        if (Number(answers.R7.qweight) >= 1) {
          return true;
        }

        return false;
      },
    },
    needs_violent: {
      isAnswer: includes(["R8"]),
      label: "History of Violent Offenses",
      score: (answers) => {
        if (Number(answers.R8.qweight) >= 1) {
          return true;
        }
        return false;
      },
    },
    needs_education: {
      isAnswer: includes(["R9"]),
      label: "Education",
      score: (answers) => {
        if (Number(answers.R9.qweight) >= 2) {
          return true;
        }
        return false;
      },
    },
    needs_employment: {
      isAnswer: includes(["R10"]),
      label: "Employment",
      score: (answers) => {
        if (Number(answers.R10.qweight) >= 1) {
          return true;
        }
        return false;
      },
    },
    needs_housing: {
      isAnswer: includes(["R11"]),
      label: "Housing",
      score: (answers) => {
        if (Number(answers.R11.qweight) >= 4) {
          return true;
        }
        return false;
      },
    },
    needs_alcohol: {
      isAnswer: includes(["R15"]),
      label: "Substance Use - Alcohol",
      score: (answers) => {
        if (Number(answers.R15.qweight) >= 2) {
          return true;
        }
        return false;
      },
    },
    needs_drugs: {
      isAnswer: includes(["R18"]),
      label: "Substance Use - Drugs",
      score: (answers) => {
        if (Number(answers.R18.qweight) >= 1) {
          return true;
        }
        return false;
      },
    },
    needs_criminal_thinking: {
      isAnswer: includes(["R19", "R20", "R21"]),
      label: "Criminal Thinking",
      score: (answers) => {
        let score = ["R19", "R20", "R21"]
          .map((val) => Number(answers[val].qweight))
          .reduce((a, v) => a + v, 0);
        if (score >= 3) {
          return true;
        }

        return false;
      },
    },
    needs_social: {
      isAnswer: includes(["R26"]),
      label: "Social Isolation",
      score: (answers) => {
        if (answers.R26.qweight === "a") {
          return true;
        }
        return false;
      },
    },
    needs_mental_health: {
      isAnswer: includes(["N1"]),
      label: "Prior Mental Health Hospitalization",
      score: (answers) => {
        if (answers.N1.qweight === "a") {
          return true;
        }
        return false;
      },
    },
    needs_psychosis: {
      isAnswer: includes(["N2"]),
      label: "Psychosis",
      score: (answers) => {
        if (answers.N2.qweight === "a") {
          return true;
        }
        return false;
      },
    },
    needs_ptsd: {
      isAnswer: includes(["N3"]),
      label: "PTSD",
      score: (answers) => {
        if (answers.N3.qweight === "a") {
          return true;
        }
        return false;
      },
    },
    needs_withdrawal: {
      isAnswer: includes(["N4"]),
      label: "Current Withdrawal Symptoms",
      score: (answers) => {
        if (answers.N4.qweight === "a") {
          return true;
        }
        return false;
      },
    },
    needs_suicidal: {
      isAnswer: includes(["N6"]),
      label: "Suicidal Ideation",
      score: (answers) => {
        if (answers.N6.qweight === "a") {
          return true;
        }
        return false;
      },
    },
    needs_attempt_suicidal: {
      isAnswer: includes(["N7"]),
      label: "Attempted Suicide",
      score: (answers) => {
        if (answers.N7.qweight === "a") {
          return true;
        }
        return false;
      },
    },
    needs_military_trauma: {
      label: "Military Sexual Trauma",
      isAnswer: includes(["N5"]),
      score: (answers) => {
        if (!answers.N5.qanswer) {
          return false;
        }
        return answers.N5.qanswer.includes("Yes, during service");
      },
    },
    needs_sexual_trauma: {
      label: "Sexual Trauma",
      isAnswer: includes(["N5"]),
      score: (answers) => {
        if (!answers.N5.qanswer) {
          return false;
        }
        return answers.N5.qanswer.includes(
          "Yes, pre-service or post-discharge"
        );
      },
    },
    /* END NEW SCORING */
    /* PREVIOUS SCORING */
    // subsection_military: { isAnswer: reggy("M\\d+"), risk: sumScores },
    // criminalRecordReview: { isAnswer: reggy("R[1-8]$"), risk: sumScores },
    // subsection_sectionII: {
    //   isAnswer: reggy("R(9|d{2})"),
    //   risk: sumScores,
    // },
    // needs_potentially_eligible: {
    //   isAnswer: includes(["M1", "M4", "M6"]),
    //   label: "Potentially Eligible for VHA Benefits",
    //   score: (answers) => {
    //     if (answers.M1.qanswer === "Yes") {
    //       if (
    //         answers.M4.qanswer.match("General discharge") ||
    //         answers.M4.qanswer.match("Honorable discharge")
    //       ) {
    //         return true;
    //       }
    //     } else if (answers.M6.qanswer === "Yes") {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_combat: {
    //   isAnswer: includes(["M5"]),
    //   label: "Combat",
    //   score: (answers) => {
    //     if (answers.M5.qweight === "a") {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_duis: {
    //   isAnswer: includes(["R3"]),
    //   label: "Multiple DUI/DWI Convictions",
    //   score: (answers) => {
    //     if (Number(answers.R3.qweight) >= 1) {
    //       return true;
    //     }

    //     return false;
    //   },
    // },
    // needs_ipv: {
    //   isAnswer: includes(["R6", "R7"]),
    //   label: "IPV - Batterer",
    //   score: (answers) => {
    //     if (Number(answers.R6.qweight) >= 1) {
    //       return true;
    //     }
    //     if (Number(answers.R7.qweight) >= 1) {
    //       return true;
    //     }

    //     return false;
    //   },
    // },
    // needs_violent: {
    //   isAnswer: includes(["R8"]),
    //   label: "History of Violent Offenses",
    //   score: (answers) => {
    //     if (Number(answers.R8.qweight) === 1) {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_education: {
    //   isAnswer: includes(["R9"]),
    //   label: "Education",
    //   score: (answers) => {
    //     if (Number(answers.R9.qweight) >= 2) {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_employment: {
    //   isAnswer: includes(["R10"]),
    //   label: "Employment",
    //   score: (answers) => {
    //     if (Number(answers.R10.qweight) >= 1) {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_housing: {
    //   isAnswer: includes(["R11"]),
    //   label: "Housing",
    //   score: (answers) => {
    //     if (Number(answers.R11.qweight) >= 4) {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_alcohol: {
    //   isAnswer: includes(["R15"]),
    //   label: "Substance Use - Alcohol",
    //   score: (answers) => {
    //     if (Number(answers.R15.qweight) >= 2) {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_drugs: {
    //   isAnswer: includes(["R18"]),
    //   label: "Substance Use - Drugs",
    //   score: (answers) => {
    //     if (Number(answers.R18.qweight) >= 1) {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_criminal_thinking: {
    //   isAnswer: includes(["R19", "R20", "R21"]),
    //   label: "Criminal Thinking",
    //   score: (answers) => {
    //     let score = ["R19", "R20", "R21"]
    //       .map((val) => Number(answers[val].qweight))
    //       .reduce((a, v) => a + v, 0);
    //     if (score >= 3) {
    //       return true;
    //     }

    //     return false;
    //   },
    // },
    // needs_social: {
    //   isAnswer: includes(["R26"]),
    //   label: "Social Isolation",
    //   score: (answers) => {
    //     if (answers.R26.qweight === "a") {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_mental_health: {
    //   isAnswer: includes(["N1"]),
    //   label: "Prior Mental Health Hospitalization",
    //   score: (answers) => {
    //     if (answers.N1.qweight === "a") {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_psychosis: {
    //   isAnswer: includes(["N2"]),
    //   label: "Psychosis",
    //   score: (answers) => {
    //     if (answers.N2.qweight === "a") {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_ptsd: {
    //   isAnswer: includes(["N3"]),
    //   label: "PTSD",
    //   score: (answers) => {
    //     if (answers.N3.qweight === "a") {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_withdrawal: {
    //   isAnswer: includes(["N4"]),
    //   label: "Current Withdrawal Symptoms",
    //   score: (answers) => {
    //     if (answers.N4.qweight === "a") {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // needs_suicidal: {
    //   isAnswer: includes(["N5"]),
    //   label: "Suicidal Ideation",
    //   score: (answers) => {
    //     if (answers.N5.qweight === "a") {
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    // // Circle if Q115 = b
    // needs_sexual_trauma: {
    //   label: "Sexual Trauma",
    //   isAnswer: includes(["Q115"]),
    //   score: (answers) => {
    //     if (!answers.Q115.qanswer) {
    //       return false;
    //     }
    //     return answers.Q115.qanswer.includes(
    //       "Yes, pre-service or post-discharge"
    //     );
    //   },
    // },
    /* END PREVIOUS SCORING */
    refusals: {
      isAnswer: reggy("[MR]\\d+"),

      score: (answers) => {
        answers = Object.values(answers);
        let score = answers
          .map((a) => a.qweight)
          .map((a) => (a === "r" ? 1 : 0))
          .reduce((accum, a) => accum + a, 0);
        return score;
      },
    },
    unknowns: {
      isAnswer: reggy("[MR]\\d+"),
      score: (answers) => {
        answers = Object.values(answers);
        let score = answers
          .map((a) => a.qweight)
          .map((a) => (a === "u" ? 1 : 0))
          .reduce((accum, a) => accum + a, 0);
        return score;
      },
    },
  };

  return runScoreCategories(answers, score_categories);
}

function calculateLongScore(answers) {
  let score_categories = {
    criminal_record_review: {
      isAnswer: reggy("R[12345678]"),
      risk: sumOrMin(17),
    },
    demographics_military_background: {
      isAnswer: includes(["Q14"]),
      risk: sumScores,
      score: () => 0,
    },
    criminal_history: {
      isAnswer: reggy("Q1[7-9][ab]?"),
      risk: sumOrMin(9),
      score: emptyScore,
    },
    employment: {
      isAnswer: reggy("Q2[0-6]"),
      risk: sumOrMin(4),
      score: emptyScore,
    },
    education: {
      isAnswer: reggy("Q(2[7-9]|3[0-3])"),
      risk: sumOrMin(4),
      score: emptyScore,
    },
    housing: {
      isAnswer: reggy("Q3[4-7]a?"),
      risk: sumOrMin(13),
      score: emptyScore,
    },
    social_env: {
      isAnswer: reggy("Q(3[8-9][abc]?|4[0-4])"),
      risk: sumOrMin(17),
      score: emptyScore,
    },
    impulsivity: {
      isAnswer: reggy("Q(4[5-9]|5[0-1]|50[ab]?)"),
      risk: subscaleRisk(14),
      score: emptyScore,
    },
    anger: {
      isAnswer: reggy("Q5[2-6]"),
      risk: subscaleRisk(12),
      score: emptyScore,
    },
    criminal_thinking_entitlement: {
      isAnswer: reggy("Q5[7-9]"),
      risk: subscaleRisk(2),
      score: sumMap,
    },
    criminal_thinking_low_empathy: {
      isAnswer: reggy("Q6[0-3]"),
      risk: subscaleRisk(3),
      score: sumMap,
    },
    egocentric: {
      isAnswer: reggy("Q64\\w"),
      risk: subscaleRisk(26.01),
      score: emptyScore,
    },
    // criminal_thinking_violence: {
    //   isAnswer: reggy("Q6[4-8]"),
    //   risk: subscaleRisk(3),
    //   score: sumMap,
    // },
    substance_abuse: {
      isAnswer: reggy("Q(69|7[0-8][ab]?)"),
      risk: sumOrMin(8),
      score: emptyScore,
    },
    dast_10: {
      isAnswer: reggy("Q(79|8[0-8][abc]?)"),
      risk: (arr) => {
        let score = sumScores(arr);
        if (score >= 3 && score <= 5) {
          return 1;
        } else if (score >= 6 && score <= 8) {
          return 2;
        } else if (score >= 9) {
          return 3;
        }
        return 0;
      },
      score: sumMap,
    },
    audit: {
      isAnswer: reggy("Q(89|9[0-8]a?)"),
      risk: (arr) => {
        let score = sumScores(arr);
        if (score >= 8 && score <= 15) {
          return 1;
        } else if (score >= 16 && score <= 19) {
          return 2;
        } else if (score >= 20) {
          return 3;
        }
        return 0;
      },
      score: sumMap,
    },
    relationships: {
      isAnswer: includes(["Q137", "Q138", "Q139"]),
      risk: sumOrMin(3),
      score: sumMap,
    },
    victimization: {
      isAnswer: reggy("Q14[0-4]a?"),
      risk: (arr) => {
        let score = sumScores(arr);
        if (score >= 6) {
          return 0;
        }
        return 0;
      },
      score: sumMap,
    },
    batterer: {
      isAnswer: reggy("Q14[5-9]a?"),
      risk: (arr) => {
        let score = sumScores(arr);
        if (score >= 6) {
          return 3;
        }
        return 0;
      },
      score: sumMap,
    },
    refusals: {
      isAnswer: reggy("."),

      score: (answers) => {
        answers = Object.values(answers);
        let score = answers
          .map((a) => a.qweight)
          .map((a) => (a === "r" ? 1 : 0))
          .reduce((accum, a) => accum + a, 0);
        return score;
      },
    },
    unknowns: {
      isAnswer: () => false,
      score: (answers) => 0,
    },
  };

  let results = runScoreCategories(answers, score_categories);
  console.log("RESULTS", results);
  let risk = Object.values(results)
    .map((r) => r.risk)
    .reduce((accum, v) => accum + v, 0);
  let risk_contributors = Object.values(results).reduce((accum, c) => {
    accum = { ...c.answers, ...accum };
    return accum;
  }, {});
  results.total_risk_score = { answers: risk_contributors, risk };

  let needs_categories = {
    "Does not qualify for VTC": {
      isAnswer: includes(["Q11"]),
      label: "Does not qualify for VTC",
      score: (answers) => {
        if (Array.isArray(answers.Q11.qanswer)) {
          answers.Q11.qanswer.forEach((answer) => {
            if (
              ["Never in Armed Forces/National Guard/Reserves"].includes(answer)
            ) {
              return true;
            }
          });
        }

        return answers.Q11.qanswer.includes(
          "Never in Armed Forces/National Guard/Reserves"
        );
      },
    },
    "Multiple DUI Convictions": {
      label: "Multiple DUI Convictions",
      isAnswer: includes(["R3"]),
      score: (answers) => {
        return answers.R3.qweight >= 1;
      },
    }, // Circle if R2a ≥  1
    "IPV Batterer – Criminal Record": {
      label: "IPV Batterer – Criminal Record",
      isAnswer: includes(["R6", "R7"]),
      score: (answers) => {
        return (
          Number(answers.R6.qweight) >= 1 || Number(answers.R7.qweight) >= 1
        );
      },
    }, // Circle if R6 or R7 = 1
    "IPV Batterer – Self Report": {
      label: "IPV Batterer – Self Report",
      isAnswer: reggy("Q14[5-9]a?"),
      score: (answers) => {
        let value = sumMap(answers);
        return value >= 6;
      },
    }, // Intimate Partner Violence Risk Score = 3 || total >= 6
    "History of Violent Crime": {
      label: "History of Violent Crime",
      isAnswer: includes(["R8"]),
      score: (answers) => {
        return Number(answers.R8.qweight) >= 1;
      },
    }, // Circle if R7 = a || There is no a, assuming 1
    "Children live w/client": {
      label: "Children live w/client",
      isAnswer: includes(["Q8"]),
      score: (answers) => {
        return answers.Q8.qweight === "a";
      },
    }, // Circle is Q8 = a || no a in this format use answer test
    "Potentially Eligible for VHA Benefits": {
      label: "Potentially Eligible for VHA Benefits",
      isAnswer: includes(["Q11b"]),
      score: (answers) => {
        let is_elligible = false;
        if (Array.isArray(answers.Q11b.qanswer)) {
          answers.Q11b.qanswer.forEach((answer) => {
            if (
              ["Retired", "General discharge", "Honorable discharge"].includes(
                answer
              )
            ) {
              is_elligible = true;
            }
          });
        }
        // console.log("Potentially Eligible for VHA Benefits", answers.Q11b.qanswer, is_elligible || answers.Q11b.qanswer.includes('Retired') ||
        //   answers.Q11b.qanswer.includes('General discharge') ||
        //   answers.Q11b.qanswer.includes('Honorable discharge'));
        return (
          is_elligible ||
          answers.Q11b.qanswer.includes("Retired") ||
          answers.Q11b.qanswer.includes("General discharge") ||
          answers.Q11b.qanswer.includes("Honorable discharge")
        );
      },
    }, // Circle if Q11b = b or c or d
    "Previously Received VHA Services": {
      label: "Previously Received VHA Services",
      isAnswer: includes(["Q11d"]),
      score: (answers) => {
        return answers.Q11d.qweight === "a";
      },
    }, // Circle if Q11d = a
    "Gun Owner": {
      label: "Gun Owner",
      isAnswer: includes(["Q11e"]),
      score: (answers) => {
        return answers.Q11e.qweight === "a";
      },
    }, // Circle if Q11e = a
    "History of Head Trauma": {
      label: "History of Head Trauma",
      isAnswer: includes(["Q15", "Q16"]),
      score: (answers) => {
        answers = answers.Q15.qanswer.split("|\\|");
        return !(
          answers.includes("Never sustained a head injury") ||
          answers.includes("Refuse to answer")
        );
      },
    }, // Circle if History of Head Trauma is “a”, Q15 >= 1
    "DDRI-2 Military Experiences": {
      label: "DDRI-2 Military Experiences",
      isAnswer: reggy("DDRI-2_"),
      score: (answers) => `${sumMap(answers)} out of 102`,
      scoreDisplay: (score) => `${score} out of 102`,
    },
    "Employment/Job Training": {
      label: "Employment/Job Training",
      isAnswer: includes(["Q20", "Q25"]),
      score: (answers) => {
        // console.log("Employment/Job Training", answers.Q20.qweight, answers.Q25.qweight, Number(answers.Q20.qweight) >= 1 || answers.Q25.qweight === "a")
        return Number(answers.Q20.qweight) >= 1 || answers.Q25.qweight === "a";
      },
    }, // Circle if Q20 = 1 or Q25 = a
    Education: {
      label: "Education",
      isAnswer: includes(["Q28"]),
      score: (answers) => {
        return Number(answers.Q28.qweight) >= 2;
      },
    }, // Circle if Q28 = 2
    Housing: {
      label: "Housing",
      isAnswer: includes(["Q34"]),
      score: (answers) => {
        return Number(answers.Q34.qweight) >= 4;
      },
    }, // Circle if Q34 = 4
    "Social Isolation": {
      label: "Social Isolation",
      isAnswer: includes(["Q38b"]),
      score: (answers) => {
        return answers.Q38b.qweight === "a";
      },
    }, // Circle if Q38b = a
    "Peer Associations": {
      label: "Peer Associations",
      isAnswer: includes(["Q39", "Q40", "Q41", "Q42", "Q43", "Q44"]),
      score: (answers) => `${sumMap(answers)} out of 17`,
      scoreDisplay: (score) => `${score} out of 17`,
    },
    "Post Military Social Support": {
      label: "Post Military Social Support",
      isAnswer: reggy("DDRI2_O"),
      score: (answers) => {
        console.log(answers, sumMap(answers));
        return `${sumMap(answers)} out of 50`;
      },
      scoreDisplay: (score) => `${score} out of 50`,
    },
    Impulsivity: {
      label: "Impulsivity",
      isAnswer: includes([
        "Q45",
        "Q46",
        "Q47",
        "Q48",
        "Q49",
        "Q50",
        "Q50a",
        "Q50b",
      ]),
      score: (arr) => {
        let sum = sumMap(arr);
        console.log("IMPULSIVITY", arr, sum);
        return sum >= 14 ? "True" : "False";
      },
      // scoreDisplay: (arr) => {
      //   let sum = sumMap(arr);
      //   console.log('IMPULSIVITY', arr, sum);
      //   return sum >= 14 ? True : 0;
      // },
    },
    Anger: {
      label: "Anger",
      isAnswer: reggy("Q5[2-6]"),
      score: (answers) => {
        let score = sumMap(answers);
        return score >= 12;
      },
    }, // Circle if Anger Risk Score = 1 || score >= 12
    "Criminal Thinking - High Entitlement": {
      label: "Criminal Thinking - High Entitlement",
      isAnswer: reggy("Q5[7-9]"),
      score: (answers) => {
        let score = sumMap(answers, "entitlement");
        return score >= 2;
      },
    }, // Circle if Criminal Thinking - High Entitlement Risk Score = 1 || >= 2
    "Criminal Thinking - Low Empathy": {
      label: "Criminal Thinking - Low Empathy",
      isAnswer: reggy("Q6[0-3]"),
      score: (answers) => {
        let score = sumMap(answers);

        return score >= 3;
      },
    }, // Circle if Criminal Thinking - Low Empathy Risk Score = 1 || >= 3
    "Criminal Thinking - Egocentric Bias": {
      label: "Criminal Thinking - Egocentric Bias",
      isAnswer: reggy("Q64\\w"),
      score: (answers) => {
        let score = sumMap(answers);
        return score > 26.01;
      },
    },
    "TCU Drug Screen V Subscale": {
      label: "TCU Drug Screen V Subscale",
      isAnswer: includes([
        "Q79",
        "Q80",
        "Q81",
        "Q82",
        "Q83",
        "Q84",
        "Q85",
        "Q86",
        "Q87",
        "Q87a",
        "Q87b",
        "Q87c",
        "Q88",
      ]),
      score: (answers) => {
        let copy = { ...answers };
        let score = 0;
        if (answers["Q87a"].qweight || answers["Q87b"].qweight) {
          score++;
          delete copy["Q87a"];
          delete copy["Q87b"];
        }
        if (answers["Q87c"].qweight || answers["Q88"].qweight) {
          score++;
          delete copy["Q87c"];
          delete copy["Q88"];
        }

        Object.keys(copy).forEach((answer) => {
          score += Number(answers[answer].qweight);
        });

        return score;
      },
      scoreDisplay: (answers) => {
        return 0;
      },
    },

    // "Criminal Thinking - High Violence & Manipulation": {
    //   label: "Criminal Thinking - High Violence & Manipulation",
    //   isAnswer: reggy("Q6[4-8]a?"),
    //   score: (answers) => {
    //     let score = sumMap(answers);
    //     return score >= 3;
    //   },
    // }, // Circle if Criminal Thinking - High Violence & Manipulation Risk Score = 1 || >= 3
    // "DAST-10 Subscale": {
    //   label: "DAST-10 Subscale",
    //   isAnswer: reggy("Q(79|8[0-8][abc]?)"),

    //   score: (answers) => {
    //     return sumMap(answers);
    //   },
    // }, // Raw score and category
    "Substance Use – Drugs": {
      label: "Substance Use – Drugs",
      isAnswer: reggy("Q(79|8[0-8][abc]?)"),
      score: (answers) => {
        let score = sumMap(answers);
        return sumMap(answers) > 0;
      },
    }, // Circle if DAST-10 Risk Score = 1 or higher || >= 3
    "AUDIT Subscale": {
      label: "AUDIT Subscale",
      isAnswer: reggy("Q(89|9[0-8]a?)"),
      score: (answers) => {
        return sumMap(answers);
      },
    }, // Raw score and category
    "Substance Use - Alcohol": {
      label: "Substance Use - Alcohol",
      isAnswer: reggy("Q(89|9[0-8]a?)"),
      score: (answers) => {
        let score = sumMap(answers);
        return sumMap(answers) > 0;
      },
    }, // Circle if AUDIT Risk Score = 1 or higher || >= 8
    "Medication for MI": {
      label: "Medication for MI",
      isAnswer: includes(["Q100"]),
      score: (answers) => {
        return answers.Q100.qweight === "a";
      },
    }, // Circle if Q100 = a
    "Prior Hospitalization": {
      label: "Prior Hospitalization",
      isAnswer: includes(["Q102"]),
      score: (answers) => {
        return answers.Q102.qweight === "a";
      },
    }, // Circle if Q102 = a
    "Potential Difficulties w/Paying Attention": {
      label: "Potential Difficulties w/Paying Attention",
      isAnswer: includes(["Q103"]),
      score: (answers) => {
        return answers.Q103.qweight === "a";
      },
    }, // Circle if Q103 = a
    "Suicidal Ideation": {
      label: "Suicidal Ideation",
      isAnswer: includes(["Q104"]),
      score: (answers) => {
        return answers.Q104.qweight === "a";
      },
    }, // Circle if Q104 = a
    "Attempted Suicide": {
      label: "Attempted Suicide",
      isAnswer: includes(["Q105"]),
      score: (answers) => {
        return answers.Q105.qweight === "a";
      },
    }, // Circle if Q105 = a
    "Mental Health - Psychosis": {
      label: "Mental Health - Psychosis",
      isAnswer: includes(["Q107", "Q108"]),
      score: (answers) => {
        let score = sumMap(answers);
        return score >= 1;
      },
    }, // Circle if BMHJS - Psychosis Subtotal is ≥ 1
    "Mental Health - Depression": {
      label: "Mental Health - Depression",
      isAnswer: includes(["Q109", "Q110", "Q111", "Q112", "Q113"]),
      score: (answers) => {
        let score = sumMap(answers);
        return score >= 2;
      },
    }, // Circle if BMHJS – Depression Subtotal is ≥ 2
    "Victim of violent crime": {
      label: "Victim of violent crime",
      isAnswer: includes(["Q114"]),
      score: (answers) => {
        return answers.Q114.qanswer === "Yes";
      },
    }, // Circle if Q114 = b
    "Military Sexual Trauma": {
      label: "Military Sexual Trauma",
      isAnswer: includes(["Q115"]),
      score: (answers) => {
        if (!answers.Q115.qanswer) {
          return false;
        }
        return answers.Q115.qanswer.includes("Yes, during service");
      },
    }, // Circle if Q115 = b
    "Sexual Trauma": {
      label: "Sexual Trauma",
      isAnswer: includes(["Q115"]),
      score: (answers) => {
        if (!answers.Q115.qanswer) {
          return false;
        }
        return answers.Q115.qanswer.includes(
          "Yes, pre-service or post-discharge"
        );
      },
    }, // Circle if Q115 = c
    "Trauma – Physical Abuse": {
      label: "Trauma – Physical Abuse",
      isAnswer: includes(["Q116"]),
      score: (answers) => {
        return answers.Q116.qanswer.includes("Physical abuse");
      },
    }, // Circle if Q116 = a
    "Trauma – Emotional Abuse": {
      label: "Trauma – Emotional Abuse",
      isAnswer: includes(["Q116"]),
      score: (answers) => {
        return answers.Q116.qanswer.includes("Emotional abuse");
      },
    }, // Circle if Q116 = b
    "Trauma – Sexual Abuse": {
      label: "Trauma – Sexual Abuse",
      isAnswer: includes(["Q116"]),
      score: (answers) => {
        return answers.Q116.qanswer.includes("Sexual abuse");
      },
    }, // Circle if Q116 = c
    "Trauma – Neglect": {
      label: "Trauma – Neglect",
      isAnswer: includes(["Q116"]),
      score: (answers) => {
        return answers.Q116.qanswer.includes("Neglect");
      },
    }, // Circle if Q116 = d
    PTSD: {
      label: "PTSD",
      isAnswer: reggy("Q1(1[7-9]|2d|3[0-6]a?)"),
      score: (answers) => {
        let value = sumMap(answers);

        return (
          value >= 31 || answers["Q136a"].qanswer === "PTSD already established"
        );
      },
    }, // Circle if Recent Trauma Symptoms Need Flag is “a” or “1” (PTSD already established) || >= 31
    "IPV - Victim": {
      label: "IPV - Victim",
      isAnswer: reggy("Q14[0-4]"),
      score: (answers) => {
        let value = sumMap(answers);
        return value >= 6;
      },
    }, // Circle if Intimate Partner Violence – Victimization is “a” || >= 6
    // "Traumatic experience during military service": {
    //   label: "Traumatic experience during military service",
    //   isAnswer: reggy("DDRI-2_Q\\d\\d?"),
    //   // score: (answers) => {
    //   //   // console.log(
    //   //   //   "VALUES",
    //   //   //   Object.values(answers).filter((answer) => Number(answer.qweight) >= 2)
    //   //   // );
    //   //   return sumMap(answers);
    //   //   // return (
    //   //   //   Object.values(answers).filter((answer) => Number(answer.qweight) >= 2)
    //   //   //     .length > 0
    //   //   // );
    //   // },
    //   score: (answers) => `${sumMap(answers)} out of 102`,
    //   scoreDisplay: (score) => `${score} out of 102`,
    // },
  };

  needs_categories = _.mapKeys(needs_categories, (val, k) => `needs_${k}`);
  needs_categories = runScoreCategories(answers, needs_categories);
  return { ...results, ...needs_categories };
}

function subscaleRisk(threshold) {
  return (arr) => {
    let sum = sumScores(arr);
    return sum >= threshold ? 1 : 0;
  };
}

function emptyScore() {
  return 0;
}

function sumMap(map, section) {
  let values = Object.values(map);
  return sumScores(values.map((v) => Number(v.qweight)));
}

function sumScores(arr) {
  return arr.reduce((accum, val) => accum + (Number(val) || 0), 0);
}

function includes(arr) {
  let i = function (val) {
    return arr.includes(val);
  };
  i._audit = arr;
  return i;
}
function reggy(reg) {
  let r = function (val) {
    return new RegExp(reg).exec(val);
  };
  r._audit = reg;
  return r;
}
function sumOrMin(min) {
  let s = function (arr) {
    let sum = sumScores(arr);
    return Math.min(min, sum);
  };
  s._audit = min;
  return s;
}

function runScoreCategories(answers, score_categories) {
  if (!Object.keys(answers).length) {
    return;
  }

  let results = {};

  for (let [key, value] of Object.entries(score_categories)) {
    let associated_answers = getAssociatedAnswers(value.isAnswer, answers);

    let associated_arr = Object.values(associated_answers).map(
      (v) => v.qweight
    );
    value.risk = value.risk || emptyScore;
    value.score = value.score || emptyScore;
    let score = value.risk(associated_arr);
    let needs_result;
    try {
      needs_result = value.score(associated_answers);
    } catch (e) {
      needs_result = false;
    }

    results[key] = {
      answers: associated_answers,
      risk: score,
      label: value.label || "",
      needs_result,
      _audit: {
        answer_matcher: `matcher = ${
          value.isAnswer._audit
        } \n${value.isAnswer.toString()}`,
        scoring_function: `${value.score.toString()}`,
        risk_function: `${value.risk.toString()}`,
      },
    };
  }

  console.log(results);
  return results;

  function getAssociatedAnswers(filter_fn, answers) {
    let associated_answers = Object.keys(answers).filter(filter_fn);
    associated_answers = associated_answers.reduce((accum, key) => {
      accum[key] = answers[key];
      return accum;
    }, {});
    return associated_answers;
  }
}

function saveScoreReport(scoreData, filename, participant_name) {
  var a = document.createElement("a");
  let Markup = (props) => (
    <Column>
      <ReportTitle>Results for Participant: {participant_name}</ReportTitle>{" "}
      {props.children}
    </Column>
  );
  let rows = { scoring: [], needs: [] };
  for (let [key, val] of Object.entries(scoreData)) {
    if (val.label) {
      //needs
      rows.needs.push(
        <Row>
          <Title>{val.label}</Title>
          <Title>Result: {val.needs_result.toString()}</Title>
          <Row>
            {_.map(val.answers, (val, key) => {
              return (
                <Column>
                  <b>Q. {key}</b>
                  <div>Answer: {val.qanswer}</div>
                  <div>Score: {val.qweight}</div>
                </Column>
              );
            })}
          </Row>
        </Row>
      );
    } else {
      rows.scoring.push(
        <Row>
          <Title>
            {humanize(key)} - {val.risk}
          </Title>
          <Row>
            {_.map(val.answers, (val, key) => {
              return (
                <Column>
                  <b>Q. {key}</b>
                  <div>Answer: {val.qanswer}</div>
                  <div>Score: {val.qweight}</div>
                </Column>
              );
            })}
          </Row>
        </Row>
      );
    }
  }
  let scoring = [...rows.scoring];
  scoring.shift(); //Remove risk score.
  let markup = ReactDOMServer.renderToStaticMarkup(
    <Markup>
      <ScoringSection>{scoring}</ScoringSection>
      <NeedsSection>{rows.needs}</NeedsSection>
    </Markup>
  );
  a.setAttribute("href", "data:text/html," + encodeURIComponent(markup));
  a.setAttribute("download", filename);
  a.click();

  a.parentNode && a.parentNode.removeChild(a);
}

function AuditReport(text) {}

function ReportTitle({ children }) {
  return <h1>{children}</h1>;
}
function Title({ children }) {
  return <h3>{children}</h3>;
}

function NeedsSection({ children }) {
  return (
    <Row>
      <h2>Needs Evaluation</h2>
      {children}
    </Row>
  );
}

function ScoringSection({ children }) {
  return (
    <Row>
      <h2>Scoring Evaluation</h2>
      {children}
    </Row>
  );
}
